.simplebar-scrollbar{
    right: 0px !important;
}

.simplebar-scrollbar::before {
    background-color: var(--accent) !important;
    transition: background-color 0.2s ease-in-out !important;
    border-radius: 20px !important;
}

.simplebar-scrollbar.simplebar-visible:before{
    opacity: 1 !important;
}

.simplebar-track{
    border-radius: 10px !important;
    outline: 2px solid var(--disabled-outline);
    /* width: 14px !important; */
    transition: width 0.2s ease-in-out !important;
}

.simplebar-track.simplebar-hover,
.simplebar-dragging .simplebar-track{
    /* width: 18px !important; */
    transition: width 0.2s ease-in-out !important;
}

.simplebar-track.simplebar-hover .simplebar-scrollbar::before,
.simplebar-dragging .simplebar-scrollbar::before {
    background-color: var(--accent-shadow) !important;
    transition: background-color 0.2s ease-in-out !important;
}