.simplebar-scrollbar {
  right: 0 !important;
}

.simplebar-scrollbar:before {
  background-color: var(--accent) !important;
  border-radius: 20px !important;
  transition: background-color .2s ease-in-out !important;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1 !important;
}

.simplebar-track {
  outline: 2px solid var(--disabled-outline);
  border-radius: 10px !important;
  transition: width .2s ease-in-out !important;
}

.simplebar-track.simplebar-hover, .simplebar-dragging .simplebar-track {
  transition: width .2s ease-in-out !important;
}

.simplebar-track.simplebar-hover .simplebar-scrollbar:before, .simplebar-dragging .simplebar-scrollbar:before {
  background-color: var(--accent-shadow) !important;
  transition: background-color .2s ease-in-out !important;
}
/*# sourceMappingURL=index.0bf75973.css.map */
